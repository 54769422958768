const config = {
  baseUrl: window.location.origin,
  primaryColorLight: "#5a30a1",
  primaryColor: "#4c2988",
  primaryColorDark: "#170a2e",
  textColor: "#ffffff",
  navBarLinkTitles: {
    home: "Hero",
    testimonials: "Vouches",
    features: "Features",
    highlights: "Servers",
    pricing: "Pricing",
    faq: "FAQ",
  },
  heroSection: {
    title: {
      regular: "Sober",
      primary: "Network",
    },
    subtitle: "We host a gaming community across mulitple games that give real cash rewards!",
    discordLink: "https://discord.gg/sobriety",
    discordText: "Discord",
    youtubeLink: "https://www.youtube.com/channel/UCe6mBoT6i0H0fVun6gzS4sw",
    youtubeText: "YouTube",
    twitchLink: "https://www.twitch.tv/soberfunds",
    twitchText: "Twitch",
    xLink: "https://x.com/SoberFunds",
    xText: "SoberFunds",
    caption: "Find us on social media!",
  },
  testimonialsSection: {
    title: "Vouches",
    description:
      "These are some of the people that have been paid real money for winning tournaments or won a giveaway!",
    testimonials: [
      {
        name: "Remy Sharp",
        testimonial:
          "I absolutely love how versatile this product is! Whether I'm tackling work projects or indulging in my favorite hobbies, it seamlessly adapts to my changing needs. Its intuitive design has truly enhanced my daily routine, making tasks more efficient and enjoyable.",
      },
      {
        name: "Travis Howard",
        testimonial:
          "One of the standout features of this product is the exceptional customer support. In my experience, the team behind this product has been quick to respond and incredibly helpful. It's reassuring to know that they stand firmly behind their product.",
      },
      {
        name: "Cindy Baker",
        testimonial:
          "The level of simplicity and user-friendliness in this product has significantly simplified my life. I appreciate the creators for delivering a solution that not only meets but exceeds user expectations.",
      },
      {
        name: "Julia Stewart",

        testimonial:
          "I appreciate the attention to detail in the design of this product. The small touches make a big difference, and it's evident that the creators focused on delivering a premium experience.",
      },
      {
        name: "John Smith",

        testimonial:
          "I've tried other similar products, but this one stands out for its innovative features. It's clear that the makers put a lot of thought into creating a solution that truly addresses user needs.",
      },
      {
        name: "Daniel Wolf",

        testimonial:
          "The quality of this product exceeded my expectations. It's durable, well-designed, and built to last. Definitely worth the investment!",
      },
    ],
  },
  featuresSection: {
    title: "Features",
    description:
      "We host prize tournaments for our community! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia non dolor consequatur similique sunt aliquid natus consequuntur adipisci corrupti illo!",
    gamesOffered: [
      {
        title: "Call of Duty",
        icon: "/assets/images/logo/cod.svg",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, quos.",
      },
      {
        title: "Call of Duty",
        icon: "/assets/images/logo/cod.svg",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, quos.",
      },
      {
        title: "Call of Duty",
        icon: "/assets/images/logo/cod.svg",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, quos.",
      },
    ],
  },
  highlightsSection: {
    title: "Servers",
    description:
      "These are all of the games that we currently manage and run tournaments, servers or giveaways on and a small description on what each server provides and offers",
    highlights: [
      {
        tabText: "COD",
        title: "Call of Duty",
        icon: "/assets/images/logo/cod.svg",
        description:
          "Our COD Server is a semi toxic sniping community that welcomes players of all kinds to join our empire! We offer a unique gameplay experience on PC for old generation titles and host tournaments across all Call of Duty games with real cash rewards. Whether you're into casual gaming, competitive cash tournaments, or climbing the leaderboard, our Discord community has something for everyone. New and dedicated players are rewarded with in-game exclusives and occasional giveaway tickets for a chance to win real cash. Explore our socials for a glimpse of what our Network offers, and show us you have what it takes to compete! ",
      },
      {
        tabText: "GTA",
        title: "GTA RP",
        icon: "/assets/images/logo/gtasvg.svg",
        description:
          "Sober Network hosts a 100k or die FiveM server, offering players a semi serious role playing experience renowned for its uniqueness, humor, and entertainment. The server rewards new members and dedicated players with special perks, including in-game custom exclusives and real money! Watch videos of players enjoying their experience, and join our Discord to be part of this unforgettable journey!",
      },
      {
        tabText: "MC",
        title: "Minecraft",
        icon: "/assets/images/logo/minecraft.svg",
        description:
          "More info will come soon as possible since we are in develeopment of our up and coming Factions Minecraft Server!",
      },
    ],
  },
  faqSection: {
    title: "FAQ",
    description:
      "The most asked questions about our COD community, GTA community and MC community",
    questions: [
      {
        question:
          "How do you sign up for tournaments?",
        answer:
          "Join our discord, follow at least 2 of our socials, provide proof that you have followed socials and you will be given the ability to sign up for the ongoing tournament!",
      },
      {
        question:
          "How does the ladder system work?",
        answer:
          "Our ladder system is run off of a \"King of the Hill\" ranked system, where you will be able to challenge and play people for their current rank and beating them rewards you with their rank. More details can be found within our discord! ",
      },
      {
        question:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, quos.",
        answer:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, quos.",
      },
      {
        question:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, quos.",
        answer:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, quos.",
      },
    ],
  },
  footer: {
    footerTopSection: {
      secondColumn: {
        title: "Company",
        links: [
          {
            name: "About Us",
            link: "/",
          },
          {
            name: "Careers",
            link: "/",
          },
          {
            name: "Press",
            link: "/",
          },
        ],
      },
      thirdColumn: {
        title: "Legal",
        links: [
          {
            name: "Terms",
            link: "/",
          },
          {
            name: "Privacy",
            link: "/",
          },
          {
            name: "Contact",
            link: "/",
          },
        ],
      },
    },
  },
};

export default config;
